import React, { useState } from 'react'
import style from './FindJobs.module.css';
import { findJobsImg } from '../../assets';
import FileUpload from '../../components/FileUpload';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { notifyError, notifySuccess } from '../../helpers/utils';

function FindJobs() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        type: 'FIND_JOB',
        name: '',
        phone: '',
        email: '',
        qualifications: '',
        message: '',
        file: null,
        experience: '',
        designation: '',
    });
    const handleInputClick = (e) => {
        e.target.addEventListener("mousewheel", (ev) => {
            e.target.blur();
        });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({
            ...errors,
            [name]: ''
        })
    };
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFormData({ ...formData, file: selectedFile });
        setErrors({
            ...errors,
            file: null
        })
    };
    const removeFile = () => {
        setFormData({ ...formData, file: null });
    }
    const validate = () => {
        let tempErrors = {};
        tempErrors.name = formData.name ? '' : 'Please Enter Your Name';
        tempErrors.phone = formData.phone ? (formData.phone.match(/^\d{9,13}$/) ? '' : 'Please Enter Valid Phone Number') : 'Please Enter Your Phone number';
        tempErrors.email = formData.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/) ? '' : 'Please Enter valid Email';

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === '');
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            setError(null);

            try {
                const formDataToSend = new FormData();
                formDataToSend.append('type', formData.type);
                formDataToSend.append('name', formData.name);
                formDataToSend.append('phone', formData.phone);
                formDataToSend.append('email', formData.email);
                formDataToSend.append('qualifications', formData.qualifications);
                formDataToSend.append('message', formData.message);
                formDataToSend.append('file', formData.file);  // Append the file here
                formDataToSend.append('experience', formData.experience);
                formDataToSend.append('designation', formData.designation);

                const response = await fetch('https://www.nufolks.com/mail/mail.php', {
                    method: 'POST',
                    body: formDataToSend,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                notifySuccess("Submitted Successfully");
            } catch (error) {
                setError(error.message);
                notifyError('Oops! Something Went Wrong. Please try again Later');
            } finally {
                setLoading(false);
                navigate('/');  
            }
        }
    };
    return (
        <>
            {
                loading ?
                    <Loader /> :
                    <div className={style.findJobs_section}>
                        <div className={style.main_container}>
                            <div className={style.main_container_box} data-aos="fade-right" data-aos-duration="1000">
                                <div className={style.main_container_box_image}>
                                    <img src={findJobsImg} alt='find jobs' />
                                </div>
                                <div className={style.main_container_heading} data-aos="fade-right" data-aos-duration="1000">
                                    <h1>Start Your Career Journey at NuFolks: Uncover Your Path to Success!</h1>
                                </div>
                            </div>
                            <div className={style.main_container_context}>
                                <p data-aos="fade-up" data-aos-duration="1000">Reach out to us as a candidate and we'll match you with the ideal company that aligns with your skills and career goals, paving the way for a rewarding and fulfilling professional journey. At NuFolks, we specialize in forging lasting connections that empower individuals to thrive in their chosen fields. We are committed to giving you the support and guidance you need to achieve your professional goals.</p>
                                <p className={style.impText} data-aos="fade-up" data-aos-duration="1000">Include yourself in our candidate pool and discover how we prioritize your career goals</p>
                                <h1 className='mainHeading' data-aos="fade-right" data-aos-duration="1000">Submit Your Profile</h1>
                                <p data-aos="fade-up" data-aos-duration="1000">Upload your job profile and resume using the form below to apply for exciting opportunities at NuFolks. Take the first step towards a fulfilling career with us!</p>
                            </div>
                        </div>
                        <div className={`form-container ${style.formWrapper}`} data-aos="flip-left" data-aos-duration="1000">
                            <form className="form" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label for="name">Name</label>
                                    <input
                                        required=""
                                        name="name"
                                        id="name"
                                        type="text"
                                        placeholder="Enter your Full Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                    {errors.name && <p className="form_error">{errors.name}</p>}
                                </div>
                                <div className="form-group">
                                    <label for="phone">Phone Number</label>
                                    <input
                                        required=""
                                        name="phone"
                                        id="phone"
                                        type="number"
                                        placeholder="Enter your Phone Number"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        onClick={handleInputClick}
                                        onKeyDown={(e) =>
                                            (e.keyCode === 38 || e.keyCode === 40) &&
                                            e.preventDefault()
                                        }
                                        onKeyPress={(e) =>
                                            ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                                e.which > 57) &&
                                            e.preventDefault()
                                        }
                                    />
                                    {errors.phone && <p className="form_error">{errors.phone}</p>}
                                </div>
                                <div className="form-group">
                                    <label for="email">Email</label>
                                    <input
                                        required=""
                                        name="email"
                                        id="email"
                                        type="text"
                                        placeholder="Enter your Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <p className="form_error">{errors.email}</p>}
                                </div>
                                <div className="form-group">
                                    <label for="qualifications">Qualifications</label>
                                    <input
                                        name="qualifications"
                                        id="qualifications"
                                        type="text"
                                        placeholder='Enter your Qualifications'
                                        value={formData?.qualifications}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="experience">Experience (Yrs) or Fresher</label>
                                    <input
                                        name="experience"
                                        id="experience"
                                        type="text"
                                        placeholder='Enter your experience'
                                        value={formData?.experience}
                                        onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label for="designation">Designation</label>
                                    <input
                                        name="designation"
                                        id="designation"
                                        type="text"
                                        placeholder='Enter your Desigation'
                                        value={formData?.designation}
                                        onChange={handleChange} />
                                </div>
                                <div className="form-group">
                                    <label for="uploadJd">Upload Resume</label>
                                    <FileUpload
                                        file={formData.file}
                                        onFileChange={handleFileChange}
                                        removeFile={removeFile}
                                    />
                                </div>

                                <div className="form-group" style={{ gridColumn: '1 / -1' }}>
                                    <label for="textarea">Message</label>
                                    <textarea
                                        cols="50"
                                        rows="10"
                                        id="textarea"
                                        name="message"
                                        placeholder="Enter your message"
                                        value={formData.message}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                                <div className={style.btnContainer} style={{ gridColumn: '1 / -1' }}>
                                    <button type="submit" className="submit-btn" disabled={loading}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
            }

        </>
    )
}

export default FindJobs