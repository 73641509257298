import React, { useState } from 'react'
import style from "./LookingToHirePage.module.css";
import Skills from '../../components/Skills';
import Select from 'react-select';
import { customStyles } from '../../helpers/constants';
import FileUpload from '../../components/FileUpload';
import { lookingToHireImg } from '../../assets';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { notifyError, notifySuccess } from '../../helpers/utils';

function LookingToHirePage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        type: 'LOOKING_TO_HIRE',
        name: '',
        phone: '',
        email: '',
        organizationName: '',
        message: '',
        file: null,
        service: null,
    });

    const handleInputClick = (e) => {
        e.target.addEventListener("mousewheel", (ev) => {
            e.target.blur();
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({
            ...errors,
            [name]: ''
        })
    };

    const handleSelectChange = (selectedOption) => {
        setFormData({ ...formData, service: selectedOption.value });
    };
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFormData({ ...formData, file: selectedFile });
        setErrors({
            ...errors,
            file: null
        })
    };
    const removeFile = () => {
        setFormData({ ...formData, file: null });
    }
    const validate = () => {
        let tempErrors = {};
        tempErrors.name = formData.name ? '' : 'Please Enter Your Name';
        tempErrors.phone = formData.phone ? (formData.phone.match(/^\d{9,13}$/) ? '' : 'Please Enter Valid Phone Number') : 'Please Enter Your Phone number';
        tempErrors.email = formData.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/) ? '' : 'Please Enter valid Email';

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === '');
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            setError(null);

            try {
                const formDataToSend = new FormData();
                formDataToSend.append('type', formData.type);
                formDataToSend.append('name', formData.name);
                formDataToSend.append('phone', formData.phone);
                formDataToSend.append('email', formData.email);
                formDataToSend.append('organizationName', formData.organizationName);
                formDataToSend.append('message', formData.message);
                formDataToSend.append('file', formData.file); 
                formDataToSend.append('service', formData.service);

                const response = await fetch('https://www.nufolks.com/mail/mail.php', {
                    method: 'POST',
                    body: formDataToSend,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                notifySuccess("Submitted Successfully");
            } catch (error) {
                setError(error.message);
                notifyError('Oops! Something Went Wrong. Please try again Later');
            } finally {
                setLoading(false);
                navigate('/');
            }
        }
    };

    const options = [
        { value: 'Full-Cycle Recruitment', label: 'Full-Cycle Recruitment' },
        { value: 'Talent Management', label: 'Talent Management' },
        { value: 'HR Consulting', label: 'HR Consulting' },
        { value: 'Permanent Staffing Solutions', label: 'Permanent Staffing Solutions' },
        { value: 'ATS', label: 'ATS' },
        { value: 'FoxMatrix', label: 'FoxMatrix' },
    ];

    return (
        <>
            {
                loading ?
                    <Loader /> :
                    <div className={style.lookingToHirePage_section}>
                        <div className={style.hire_top_container}>
                            <div className={style.top_container_heading_box} data-aos="fade-right" data-aos-duration="1000">
                                <h1 data-aos="fade-right" data-aos-duration="1000">Discover Exceptional Talent: Elevate Your Team with Our Opportunities!</h1>
                                <img src={lookingToHireImg} alt='Looking to Hire' data-aos="fade-right" data-aos-duration="1000" />
                            </div>
                            <div className={style.top_container_box}>
                                <p data-aos="fade-up" data-aos-duration="1000"> Enroll and witness our meticulous process of filtering out the ordinary to deliver extraordinary candidates. At NuFolks, we prioritize excellence by curating a pool of top-tier talent that meets your unique needs and exceeds your expectations. Partner with us to elevate your workforce and achieve exceptional results.</p>
                                <p className={style.impText} data-aos="fade-up" data-aos-duration="1000">Join our client pool where we expertly filter out the devil to ensure only the best candidates are matched with your needs</p>
                            </div>
                        </div>
                        <div className={style.hire_heading}>
                            <h1 className='mainHeading' data-aos="fade-right" data-aos-duration="1000">Looking To Hire</h1>
                            <p data-aos="fade-up" data-aos-duration="1000">Let NuFolks help you find the talent your organization needs. Share your details and service requirements with us, and we'll work together to meet your staffing needs efficiently and effectively.</p>
                        </div>
                        <div className={`form-container ${style.formWrapper}`} data-aos="flip-left" data-aos-duration="1000">
                            <form className="form" id='lookingToHireForm' onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        required=""
                                        name="name"
                                        id="name"
                                        type="text"
                                        placeholder="Enter your Full Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                    {errors.name && <p className="form_error">{errors.name}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Phone Number</label>
                                    <input
                                        required=""
                                        name="phone"
                                        id="phone"
                                        type="number"
                                        placeholder="Enter your Phone Number"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        onClick={handleInputClick}
                                        onKeyDown={(e) =>
                                            (e.keyCode === 38 || e.keyCode === 40) &&
                                            e.preventDefault()
                                        }
                                        onKeyPress={(e) =>
                                            ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                                e.which > 57) &&
                                            e.preventDefault()
                                        }
                                    />
                                    {errors.phone && <p className="form_error">{errors.phone}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        required=""
                                        name="email"
                                        id="email"
                                        type="text"
                                        placeholder="Enter your Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <p className="form_error">{errors.email}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="employer_services">Employer Services</label>
                                    <Select
                                        id="employer_services"
                                        value={options.find(option => option.value === formData.service)}
                                        onChange={handleSelectChange}
                                        options={options}
                                        placeholder="Select a Service"
                                        styles={customStyles}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="organizationName">Organization Name</label>
                                    <input
                                        name="organizationName"
                                        id="organizationName"
                                        type="text"
                                        placeholder="Enter your Organization Name"
                                        value={formData.organizationName}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="uploadJd">Upload JD</label>
                                    <FileUpload
                                        file={formData.file}
                                        onFileChange={handleFileChange}
                                        removeFile={removeFile}
                                    />
                                </div>
                                <div className="form-group" style={{ gridColumn: '1 / -1' }}>
                                    <label htmlFor="textarea">Message</label>
                                    <textarea
                                        cols="50"
                                        rows="10"
                                        id="textarea"
                                        name="message"
                                        placeholder="Enter your message"
                                        value={formData.message}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                                <div className={style.btnContainer} style={{ gridColumn: '1 / -1' }}>
                                    <button type="submit" className="submit-btn" disabled={loading}>Submit</button>
                                </div>
                            </form>
                        </div>
                        <Skills />
                    </div>
            }

        </>
    )
}

export default LookingToHirePage