import React from 'react';
import { Link } from 'react-router-dom';
import style from './NotFound.module.css'
import { notFoundIcon } from '../../assets';

function NotFound() {
    return (
        <div className={style.notfound_section}>
            <div className={style.notfound_imgContainer}>
                <img src={notFoundIcon} alt='404 Page Not Found' />
            </div>
            <div>
                <h1 className={style.digit}>404</h1>
                <h1>Page Not Found</h1>
                <p>The page you are looking for does not exist.</p>
                {/* <p>Uh-oh, it seems the devil has taken over this page. Let's get you back to safety!</p> */}
                <Link to="/">Go to Home Page</Link>
            </div>

        </div>
    )
}

export default NotFound