export const customStyles = {
    control: (provided,state) => ({
      ...provided,
      backgroundColor: '#333',
      borderColor: state.isFocused ? 'var(--primary-color)' : '#555',
      color: '#fff',
      boxShadow: 'none',
      borderRadius: '8px',
      padding: '6px 8px',
      fontSize: '16px',
      '&:hover': {
        borderColor: state.isFocused ? 'var(--primary-color)' : '#777',
      }
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      color: '#fff',
      fontSize: '16px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007BFF' : state.isFocused ? '#555' : '#333',
      color: state.isSelected ? '#fff' : '#ddd',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: state.isSelected ? '#007BFF' : '#555',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff',
      fontSize: '16px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#aaa',
      fontSize: '16px',
    }),
    input: (provided) => ({
      ...provided,
      color: '#fff',
      fontSize: '16px',
    }),
  };
  