import React from 'react';
import style from './Testimonials.module.css';
import { grnConnect, passionGaming, quote, unifocus } from '../../assets';

function Testimonials() {
    return (
        <div className={style.testimonials_section} id='testimonials'>
            <h1 className='mainHeading text-center' data-aos="fade-right" data-aos-duration="1000">Testimonials</h1>
            <div className={style.testimonialsContainer}>
                <div className={style.testimonialCard} data-aos="fade-right" data-aos-duration="1000">
                    <div className={style.testimonialText}>
                        <img src={quote} alt='quote' />
                        <p>
                            NuFolks team stands out as a Recruitment Consultancy. After our initial intake call, they quickly understood our business and the requirements of the role. The team is highly professional and goes an extra mile for both the candidate and the client. It is always a pleasure working with them.
                        </p>
                    </div>
                    <div className={style.testimonialInfo}>
                        <img src={unifocus} alt='unifocus' />
                        <div className='d-flex flex-column justify-content-around ms-2'>
                            <h5>Unifocus</h5>
                            <p>USA</p>
                        </div>
                    </div>
                </div>
                <div className={style.testimonialCard} data-aos="fade-right" data-aos-duration="1000">
                    <div className={style.testimonialText}>
                        <img src={quote} alt='quote' />
                        <p>
                            It was a great pleasure working with the team. We highly appreciate your dedication and professionalism in  finding the right fit for our team. Your efforts have stood out and we look forward to continuing our partnership for future. Special Kudos to Shivali for sourcing the candidate. Thanks again for your outstanding support.
                        </p>
                    </div>
                    <div className={style.testimonialInfo}>
                        <img src={passionGaming} alt='user' />
                        <div className='d-flex flex-column justify-content-around ms-2'>
                            <h5>Passion Gaming</h5>
                            <p>Gurgaon</p>
                        </div>
                    </div>
                </div>
                <div className={`${style.testimonialCard} ${style.card_center}`} data-aos="fade-right" data-aos-duration="1000">
                    <div className={style.testimonialText}>
                        <img src={quote} alt='quote' />
                        <p>
                            I am writing to express my sincere appreciation for the service provided by NuFolks during our recent collaboration. I wanted to take a moment to convey our gratitude for the good support and professionalism demonstrated by your team throughout the recruitment process and look forward to continue our partnership.
                        </p>
                    </div>
                    <div className={style.testimonialInfo}>
                        <img src={grnConnect} alt='GRNConnect.com' />
                        <div className='d-flex flex-column justify-content-around ms-2'>
                            <h5>GRNconnect.com</h5>
                            <p>Delhi</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials