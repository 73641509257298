import React from 'react';
import style from './Services.module.css';
import { atsIcon, discussion, foxMatrixIcon, outsourcing, recruitment, statistics } from '../../assets';
function Services() {
    return (
        <div className={style.services_section}>
            <div className={style.mainContainer}>
                <h1 className='mainHeading' data-aos="fade-right" data-aos-duration="1000">Welcome to NuFolks - Your Trusted Partner in Staffing Solutions.</h1>
                <p data-aos="fade-up" data-aos-duration="1000">We specialize in delivering tailored staffing and HR solutions designed to elevate your organization's success. With a deep understanding of today's competitive landscape, we provide innovative staffing strategies that align with your unique objectives. Whether you need skilled professionals, optimized Talent management, or guidance on HR regulations, our dedicated team is here to support you every step of the way.</p>
            </div>
            <h1 className='mainHeading' data-aos="fade-right" data-aos-duration="1000">Our Services</h1>
            <div className={style.services_cards_container} data-aos="fade-in" data-aos-offset="200" data-aos-duration="1000">
                <div className={style.services_card}>
                    <div className={style.services_card_img_container}>
                        <img src={outsourcing} alt='services' />
                    </div>
                    <div>
                        <h2>Full-Cycle Recruitment</h2>
                        <p>Benefit from our end-to-end hiring solutions powered by an advanced Applicant Tracking System (ATS). ATS ensures efficient candidate management and seamless integration with your existing systems, optimizing every stage of the recruitment process.</p>
                    </div>
                </div>
                <div className={style.services_card}>
                    <div className={style.services_card_img_container}>
                        <img src={statistics} alt='services' />
                    </div>
                    <div>
                        <h2>Talent Management</h2>
                        <p>Enhance employee performance and career development strategies with our tailored talent management solutions. From talent acquisition to retention strategies, we help organizations maximize their human capital potential.</p>
                    </div>
                </div>
                <div className={style.services_card}>
                    <div className={style.services_card_img_container}>
                        <img src={discussion} alt='services' />
                    </div>
                    <div>
                        <h2>HR Consulting</h2>
                        <p>Gain expert insights and guidance on HR policies, compliance, and best practices. Our consulting services are designed to support your HR initiatives, ensuring alignment with regulatory requirements and industry standards.</p>
                    </div>
                </div>
                <div className={style.services_card}>
                    <div className={style.services_card_img_container}>
                        <img src={recruitment} alt='services' />
                    </div>
                    <div>
                        <h2>Permanent Staffing Solutions</h2>
                        <p>Streamline your long-term staffing needs with our permanent staffing solutions. We offer efficient recruitment strategies to save time and costs while ensuring you secure the right talent for sustainable organizational growth.</p>
                    </div>
                </div>
                <div className={style.services_card}>
                    <div className={style.services_card_img_container}>
                        <img src={atsIcon} alt='services' />
                    </div>
                    <div>
                        <h2>ATS</h2>
                        <p>NuFolks' comprehensive ATS optimized hiring with a full lifecycle approach, refined through feedback from recruiters and clients. It seamlessly integrates with client ATS systems, providing a mature and practical solution for efficient candidate management.</p>
                    </div>
                </div>
                <div className={style.services_card}>
                    <div className={style.services_card_img_container}>
                        <img src={foxMatrixIcon} alt='services' />
                    </div>
                    <div>
                        <h2>FoxMatrix</h2>
                        <p>FoxMatrix transforms candidate assessment with real interviewers, ensuring rapid and reliable evaluations. Utilizing a 10:3 approach, it sources candidates, filters them rigorously, and presents the top selections to clients for streamlined decision-making.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services