import React from 'react'
import Intro from '../Intro'
import Clients from '../Clients'
import Values from '../Values'
import Candidates from '../Candidates'
import Team from '../Team'
import Testimonials from '../Testimonials'
import Skills from '../../components/Skills'
import Sidebar from '../../components/Sidebar'

function Home() {
    return (
        <div>
            <Sidebar />
            <Intro />
            <Clients />
            <Values />
            <Candidates />
            <Team />
            <Testimonials />
            <Skills />
        </div>
    )
}

export default Home