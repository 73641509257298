import React from 'react'
import style from './LeaderShip.module.css';
import { Deepa, Sachin, founderImg } from '../../assets';
function LeaderShip() {
    return (
        <div className={style.leaderShip_section}>
            <div className={style.leaderShip_container}>
                <h1 className='mainHeading' data-aos="fade-right" data-aos-duration="1000">Our Founders</h1>
                <div className={style.leader_box} data-aos="fade-up" data-aos-duration="1000">
                    <div>
                        <img src={Deepa} alt='founder' />
                    </div>
                    <div>
                        <h1 className={style.name}>Deepa Seth</h1>
                        <h2 className={style.designation}>Co-Founder and Director Client Solutions</h2>
                        <p>
                        Meet Deepa Seth,one of the visionary minds behind NuFolks.With a Master's in Computer Applications (MCA) and a strong background in technology and client solutions, Deepa brings invaluable expertise to our team. She plays a pivotal role in driving client success through innovative solutions and personalized service. Her deep understanding of technology and client needs enables her to lead our team in delivering tailored solutions that exceed expectations. Join Deepa Seth and the NuFolks team in transforming industries and forging lasting client partnerships based on trust, innovation, and mutual success.
                        </p>
                    </div>
                </div>


                <div className={`${style.leader_box} ${style.leader_box_second}`} data-aos="fade-up" data-aos-duration="1000">
                    <div>
                        <h1 className={style.name}>Sachin Prakash</h1>
                        <h2 className={style.designation}>Co-Founder and Business Head</h2>
                        <p>
                        Meet Sachin Prakash, another driving force behind NuFolks. With an MBA and over 14 years of experience in operations, Sachin brings a wealth of strategic leadership and expertise to NuFolks. He is instrumental in driving our mission to connect talent with opportunity, focusing on innovation and client-centric solutions.Sachin's values of integrity, collaboration, and continuous improvement are ingrained in NuFolks' culture, shaping our growth and success.Join Sachin Prakash and the NuFolks team in shaping the future of the Staffing Industry, where every opportunity is a pathway to success.
                        </p>
                    </div>
                    <div>
                        <img src={Sachin} alt='founder' />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default LeaderShip