import React from 'react';
import style from "./CandidateCard.module.css"

function CandidateCard({ data }) {
    return (
        <div className={style.candidateCard} style={{ backgroundColor: data.background }}>
            <img src={data.avatar} />
            <div className={style.candidateCardDetails}>
                <p className={style.info}>{data.name}, {data.exp}</p>
                <p className={style.designation}>{data.designation}</p>

            </div>
        </div>
    )
}

export default CandidateCard