import React, { useState } from 'react';
import style from './Header.module.css';
import { Link, useLocation } from 'react-router-dom';
import { nufolksLogo } from '../../assets';
import { Backdrop } from '@mui/material';

const Header = () => {
    const location = useLocation();
    const [showSidebar, setShowSidebar] = useState(false);

    const openBackdrop = () => {
        setShowSidebar(!showSidebar);
    }
    const closeBackdrop = () => {
        setShowSidebar(false)
    }

    return (
        <>
            <header className={style.myheader}>
                <nav>
                    <Link to="/" className={style.navLogoLink}>
                        <img src={nufolksLogo} alt="NuFolks" className={style.navbar_logo} />
                    </Link>
                    <ul className={`${style.menubar} ${showSidebar ? style.active : ''}`}>
                        <li>
                            <Link to="/about" className={`${style.navLink} ${location.pathname === "/about" ? style.header_active : ""}`} onClick={closeBackdrop}>About Us</Link>
                        </li>
                        <li>
                            <Link to="/our-process" className={`${style.navLink} ${location.pathname === "/our-process" ? style.header_active : ""}`} onClick={closeBackdrop}>Our Process</Link>
                        </li>
                        <li>
                            <Link to="/services" className={`${style.navLink} ${location.pathname === "/services" ? style.header_active : ""}`} onClick={closeBackdrop}>Our Services</Link>
                        </li>
                        <li>
                            <Link to="/culture" className={`${style.navLink} ${location.pathname === "/culture" ? style.header_active : ""}`} onClick={closeBackdrop}>Our Culture</Link>
                        </li>
                        <li>
                            <Link to="/hire-a-talent" className={`${style.navLink} special_btn ${location.pathname === "/hire-a-talent" ? style.header_active : ""}`} onClick={closeBackdrop}>Hire a Talent</Link>
                        </li>
                        <li>
                            <Link to="/apply-as-a-talent" className={`${style.navLink} special_btn_outline ${location.pathname === "/apply-as-a-talent" ? style.header_active : ""}`} onClick={closeBackdrop}>Apply as a Talent</Link>
                        </li>
                    </ul>
                    <div className={`${style.menu} ${showSidebar ? style.active : ''}`} onClick={openBackdrop}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </nav>
            </header><Backdrop
                sx={{
                    color: "#fff",
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    zIndex: "99 !important",
                }}
                open={showSidebar}
                onClick={closeBackdrop}
            ></Backdrop>
        </>
    );
};

export default Header;
