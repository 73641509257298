import React from 'react'
import style from './PrivacyPolicy.module.css';
function PrivacyPolicy() {
    return (
        <div className={style.privacyWrapper}>
            <div className={style.privacyPolicyContainer}>
                <h1>Privacy Policy</h1>
                <p>At NuFolks, we are committed to safeguarding your privacy. This policy outlines how we collect, use, and protect your personal information.</p>
                <div className={style.policyDetail}>
                    <h2>Information Collection</h2>
                    <p>We collect personal information that you provide directly, such as your name, contact details, and resume, as well as data from your use of our services.</p>
                </div>
                <div className={style.policyDetail}>
                    <h2>Use of Information</h2>
                    <p>Your information is used to enhance our recruitment services, match you with job opportunities, and communicate with you effectively. We also use aggregated data to improve our services and user experience.</p>
                </div>
                <div className={style.policyDetail}>
                    <h2>Data Sharing</h2>
                    <p>We do not share your personal information with third parties without your consent, except as required by law or to protect our rights. We may share anonymized data with partners to enhance our services.</p>
                </div>
                <div className={style.policyDetail}>
                    <h2>Data Security</h2>
                    <p>We implement robust security measures to protect your data from unauthorized access, alteration, or disclosure. Our systems are regularly updated to maintain the highest security standards.</p>
                </div>
                <div className={style.policyDetail}>
                    <h2>Cookies and Tracking</h2>
                    <p>Our website uses cookies to enhance your browsing experience. Cookies help us understand your preferences and improve site functionality. You can manage your cookie preferences through your browser settings.</p>
                </div>
                <div className={style.policyDetail}>
                    <h2>Policy Updates</h2>
                    <p>We may update this policy periodically to reflect changes in our practices. We encourage you to review this policy regularly.</p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy