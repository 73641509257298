import React from 'react';
import style from './Values.module.css'
import ValueCard from '../../components/ValueCard'
import { aiIcon, diamondIcon, interviewIcon, linkedInIcon, technlogyIcon, valuesIcon } from '../../assets';

function Values() {
    const valuesData = [
        {
            icon: technlogyIcon,
            heading: "NuFolks runs on Technology",
            context: "Our custom ATS, video interviewing platform, and Client Interview Scheduler App streamline candidate pipelines efficiently."
        },
        {
            icon: aiIcon,
            heading: "Humans & AI Filtering Together",
            context: "We blend human expertise with AI to screen CVs, eliminate duplicates, create concise candidate summaries, ensuring optimal matches.",
        },
        {
            icon: interviewIcon,
            heading: "900+ Interviewers on our Panel",
            context: 'Our panel conducts rigorous written assessments and interviews, ensuring robust candidate evaluation with real interviewers.'
        },
        {
            icon: linkedInIcon,
            heading: "LinkedIn Hiring Automation",
            context: "Utilizing Naukri and our LinkedIn CoE, we employ top tools, paid subscriptions, and processes for effective talent scouting.",
        },
        {
            icon: diamondIcon,
            heading: "Our Recruiters are Polished Diamonds",
            context: "Our recruiters undergo comprehensive industry training, mastering NuFolks tools, processes, and delivering exceptional customer service.",
        }
    ]
    return (
        <div className={style.values_section} id='values'>
            <div className={style.valuesCardContainer}>
                <ValueCard data={valuesData[0]} />
                <div className={style.valuesHeadingContainer}>
                    <img src={valuesIcon} alt='values added' data-aos="fade-up" data-aos-duration="1000" />
                    <h1 className={`text-light text-center mb-4 mainHeading`} data-aos="fade-up" data-aos-duration="1000">Our Pillars of Success</h1>
                </div>
                <ValueCard data={valuesData[1]} />
                <ValueCard data={valuesData[2]} />
                <ValueCard data={valuesData[3]} />
                <ValueCard data={valuesData[4]} />
            </div>
        </div>
    )
}

export default Values