import React, { useEffect } from 'react'
import style from './Process.module.css';
import { FiUser } from "react-icons/fi";
import { GrDocument } from "react-icons/gr";
import { BiEdit } from "react-icons/bi";
function Process() {

    const stepData = [
        {
            num: '01',
            heading: 'KYC - Know Your Client',
            detail: 'Knowing the client is the first step in the talent sourcing & on-boarding journey. To better facilitate the process, NuFolks meets with key stakeholders important to the search to understand the organization’s history, culture and operating structure.'
        },
        {
            num: '02',
            heading: 'Develop the Position Specification',
            detail: 'The position specification document will be prepared based on our discussions.  The position specification will be reviewed with the client and serve as a guide in determining whom we will contact during the course of the search.'
        },
        {
            num: '03',
            heading: 'Targeted Search',
            detail: 'Upon approval of the Position Specification by the client, we will begin a targeted search for candidates',
            list: [
                {
                    icon: <FiUser />,
                    text: 'Naukri',
                },
                {
                    icon: <FiUser />,
                    text: 'Our Training & Premier-Candidate Database Partners',
                },
                {
                    icon: < GrDocument />,
                    text: 'LinkedIn Automation',
                },
                {
                    icon: <BiEdit />,
                    text: 'Our Internal Database & References',
                },
            ]
        },
        {
            num: '04',
            heading: 'Evaluation & Shortlist',
            detail: 'Based on our discussions, we will draw up an initial list of shortlisted candidates. This will be done after a detailed evaluation of the candidates in terms of their skill sets, experience and their interest in the mandate'
        },
        {
            num: '05',
            heading: 'Assessments',
            detail: "NuFolks judiciously utilizes technology, psychometric, industry-specific or English-language assessments to filter out the candidates not in line with client's position specification"
        },
        {
            num: '06',
            heading: 'FoxMatrix - "Real" Interviews',
            detail: 'Leverage FoxMatrix to conduct 15-45 minute interview with the candidate to assess on technology / domain / communication skills, and overall fitment for the job'
        },
        {
            num: '07',
            heading: 'Set-up & Support Client Interviews',
            detail: 'Arrange the interview between the short listed candidates with the client subject-matter expert. Leverage our Interview Scheduling App for a very smooth experience, cancellation management etc'
        },
        {
            num: '08',
            heading: 'Reference Check (Optional)',
            detail: 'NuFolks has zero tolerance to candidates who forge documents, do moonlight or double jobs, unable to produce necessary documents or have a failed background check. NuFolks background check is painstakingly diligent.'
        },
        {
            num: '09',
            heading: 'Offer Release & Negotiation',
            detail: 'NuFolks works with clients to create & present the offer and explain the same to the selected candidate. Throughout this final stage of the search process, NuFolks represents the client.'
        },
        {
            num: '10',
            heading: 'On-Boarding Support',
            detail: 'NuFolks continues to maintain the relationship with the candidate & provides complete candidate on-boarding support to the client. Newsletter, birthday wishes, welcome gifts are sent to the candidate on behalf of the client & assist client in whatever is needed for a smooth on-boarding. '
        },
    ]
    useEffect(() => {
        const handleResize = () => {
            const steps = document.querySelectorAll(`.${style.process_step}`);
            steps.forEach((step, index) => {
                if (window.innerWidth < 576) {
                    step.setAttribute('data-aos', 'fade-up');
                } else {
                    step.setAttribute('data-aos', index % 2 === 0 ? 'fade-left' : 'fade-right');
                }
            });
        };

        // Check on component mount
        handleResize();

        // Add event listener for resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [style.process_step]);
    
    return (
        <div className={style.process_section}>
            <div className={style.process_info} >
                <h1 className='mainHeading' data-aos="fade-right" data-aos-duration="1000">Unlocking Potential: Our Journey in Talent Sourcing and Onboarding</h1>
                <p data-aos="fade-up" data-aos-duration="1000">NuFolks has re-invented the traditionally run of the mill staffing process to a well-oiled machinery with a strong emphasis on candidate filtering, ensuring a superior client experience and saving clients valuable time.</p>
            </div>
            <div className={style.process_steps_container}>
                {stepData.map((step, index) =>
                (<div className={style.process_step}
                    data-aos={index % 2 == 0 ? "fade-left" : "fade-right"}
                    data-aos-duration="1000">
                    <p className={style.step_num}>{step.num}</p>
                    <h1 className={style.step_heading}>{step.heading}</h1>
                    <p className={style.step_detail}>{step.detail}</p>
                    {step?.list
                        && <>
                            <ul className={style.step_list}>
                                {
                                    step?.list?.map((item) => (
                                        <li>
                                            <span className={style.listIcon}>{item.icon}</span>
                                            {item.text}
                                        </li>
                                    ))
                                }
                            </ul>
                        </>}
                </div>))
                }

            </div>
        </div>
    )
}

export default Process