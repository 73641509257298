import React from 'react';
import style from './Applications.module.css';
import { ATS, FoxMatrix } from '../../assets';

function Applications() {
    return (
        <div className={style.applications_wrapper}>
            <div className={style.applications_container} data-aos="fade-up" data-aos-duration="1000">
                <div className={`${style.applications_container_child} ${style.applications_text_side}`}>
                    <p>APPLICANT TRACKING SYSTEM</p>
                    <h1>NuFolks  ATS</h1>
                    <div className={style.applications_details}>
                        <p>NuFolks home-grown & a full lifecycle Applicant Tracking System (ATS) makes our hiring near-perfect. Feedback from recruiters & some clients have matured the tool over a period of time. </p>
                        <p>The ATS integrates with client's ATS for a seamless flow of candidates</p>
                        <p>NuFolks ATS is available for its clients who wishes to shift to a mature and practical ATS</p>
                    </div>
                </div>
                <div className={`${style.applications_container_child} ${style.applications_img_side}`}>
                    <img src={ATS} alt='ATS' />
                </div>

            </div>
            <div className={`${style.applications_container} ${style.applications_container_second}`} data-aos="fade-up" data-aos-duration="1000">
                <div className={`${style.applications_container_child} ${style.applications_img_side}`}>
                    <img src={FoxMatrix} alt='FoxMatrix' />
                </div>
                <div className={`${style.applications_container_child} ${style.applications_text_side}`}>
                    <p>FOXMATRIX</p>
                    <h1>Interviewing Platform, with Real Interviewers</h1>
                    <div className={style.applications_details}>
                        <p>FoxMatrix enables quick, fast & reliable interviews of the candidates by real interviewers. </p>
                        <p>10:3 : Source 10 CVs, filter-out 7 and pass on 3 CVs to the client.</p>
                        <p>10:3:1 : Source 10 CVs, filter-out 7, take interview of 3 and pass on 1 CV to client.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Applications