import React from 'react';
import style from './OurProcess.module.css';
import Applications from '../../components/Applications'
import Process from '../../components/Process';

function OurProcess() {
    return (
        <div className={style.ourProcess_section}>
            <h1 className='mainHeading' data-aos="fade-right" data-aos-duration="1000">From Inquiry to Impact: Navigating Our Staffing Solutions</h1>
            <Applications />
            <Process />
        </div>
    )
}

export default OurProcess