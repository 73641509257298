import React, { useRef, useState } from 'react'
import style from './intro.module.css';
import { candidates, demoVideo, nufolksLogo, playButtonIcon } from '../../assets';

function Intro() {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const handlePlayButtonClick = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    const handleVideoLoaded = () => {
        setIsVideoLoaded(true);
    }

    const handleMouseEnter = () => {
        if (videoRef.current) {
            videoRef.current.setAttribute('controls', 'controls');
        }
    };

    const handleMouseLeave = () => {
        if (videoRef.current) {
            videoRef.current.removeAttribute('controls');
        }
    };
    return (
        <div className={style.introSection} id='dashboard'>
            <div className='text-center'>
                <div className={style.mainSection}>
                    <div className={style.mainSectionLeft}>
                        <div className={style.textSlideContainer}>
                            <div className={style.firstText}><h1 className={`text-light`}>We filter the <span className={`${style.devil}`}>Devil</span> Out</h1></div>
                            <div className={style.secondText}><h1 className={`text-light`}>And Give you the <span className={`${style.angel}`}>Extraordinary</span></h1></div>
                        </div>
                    </div>
                    <div className={style.mainSectionRight}>
                        <div className={style.videoContainer}>
                            <video
                                className={style.demo_video}
                                // autoPlay
                                // muted
                                // loop
                                src={demoVideo}
                                ref={videoRef}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                loading="lazy"
                                onLoadedData={handleVideoLoaded}
                            >
                            </video>
                            {!isPlaying && isVideoLoaded && (
                                <div className={style.video_overlay}>
                                    <div className={style.overlay_content}>
                                        <button
                                            className={style.playButton}
                                            onClick={handlePlayButtonClick}
                                        >
                                            <img src={playButtonIcon} alt='play video' />
                                        </button>
                                        <img className={style.logo} src={nufolksLogo} alt='NuFolks' />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
               
                <div className='width-100' data-aos="fade-up" data-aos-duration="1000">
                    <img src={candidates} className='width-100' alt='candidates' />
                </div>
                <div className={`text-light ${style.brief_container}`} data-aos="fade-up" data-aos-duration="1000">
                    <p>We are technology-enabled recruitment company which uses technology to help hire resources for our clients. Our recruiters are super-organized on our home-grown Applicant Tracking System (ATS) and use ‘special’ tools & some AI to build candidate pipelines at an extraordinary pace. We ourselves are sometimes confused if we are a technology company or a staffing company!</p>
                </div>
            </div>
        </div>
    )
}

export default Intro