import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./containers/Footer";
import Home from "./containers/Home";
import OurProcess from "./containers/OurProcess";
import AboutUs from "./containers/AboutUs";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Terms from "./containers/Terms/terms";
import Services from "./containers/Services";
import ScrollToTop from "./components/ScrollToTop";
import LookingToHirePage from "./containers/LookingToHirePage";
import FloatingButton from "./components/FloatingButton";
import FindJobs from "./containers/FindJobs";
import Culture from "./containers/Culture";
import NotFound from "./containers/NotFound";
import { ToastContainer } from "react-toastify";


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <FloatingButton label={'Find your Team'} url='./hire-a-talent' />
      <FloatingButton label={'Upload CV'} url='./apply-as-a-talent' />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-process" element={<OurProcess />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/services" element={<Services />} />
        <Route path="/hire-a-talent" element={<LookingToHirePage />} />
        <Route path="/apply-as-a-talent" element={<FindJobs />} />
        <Route path="/culture" element={<Culture />} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
      <ToastContainer />
      <Footer />
    </Router>
  );
}

export default App;
