import React from 'react';
import style from './FileUpload.module.css';
import { RxCrossCircled } from "react-icons/rx";

const FileUpload = ({ file, onFileChange, removeFile }) => {
    return (
        <div className={style.file_upload}>
            {file ? (
                <div className={style.file_uploaded}>
                    <p>{file?.name}</p>
                    <p
                        onClick={removeFile}
                        className={style.crossIcon}>
                        <RxCrossCircled />
                    </p>
                </div>

            ) : (
                <>
                    <input
                        type="file"
                        // accept="application/pdf"
                        onChange={onFileChange}
                        className={style.file_input}
                    />
                </>
            )}
            {/* <button onClick={onUpload} className={style.upload_button}>Upload</button> */}
            {/* {file && <p className={style.file_name}>Selected file: {file.name}</p>} */}
        </div>
    );
};

export default FileUpload;
