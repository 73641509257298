import React from 'react';
import './skills.css';

function Skills() {
    const SkillsData1 = [
        'GoLang Developer',
        'Business Analyst',
        'API Technical Support',
        'Digital Marketing',
        'Data Analyst',
        'Risk Analyst',
        'Graphic Designer',
        'Geographical Information System (GIS) and Remote Sensing (RS)',
        'IT Sales',
        'Pre-Sales - Cybersecurity',
        'Renewal Business',
        'A & R Manager',
        'UI/UX Designer',
        'Operations',
        'Product Support',
        'Learning and Development',
        'Business Development',
        'Subject Matter Experts',
        'PHP/ Laravel Developer',
        'Accounts',
        'Investment Banking',
    ]
    const SkillsData2 = [
        'L1/L2 Technical Support',
        'Product Manager',
        'Mobile App Architect',
        'Integration Lead',
        'Java Developer',
        'Devops Engineer',
        'Unity Developer',
        'React Native Developer',
        'VOIP (Voice over internet protocol) Developer',
        'Data Architect',
        'Informatica MDM Lead/ Architect',
        'Dotnet Developer',
        'PHP Developer',
        'IOS Developer',
        'Linux Admin',
        'Head of Engineering',
        'AI/ML Developer',
        'AEM Developer',
        'Content Writing',
        'CyberArk PAM',
        'SOC Expert',
    ]
    return (
        <div className='skills-section' id='skills'>
            <h1 className={`text-light text-center mainHeading`} data-aos="fade-right" data-aos-duration="1000">SKILLS WE HAVE RECRUITED AND COUNTING</h1>
            <div className='skills-container' data-aos="fade-up" data-aos-duration="1000">
                <div className='skills'>
                    <div className='skills-slide'>
                        {SkillsData1?.map((skill, index) => (
                            <span>{skill}</span>
                        ))}
                    </div>
                    <div className='skills-slide'>
                        {SkillsData1?.map((skill, index) => (
                            <span>{skill}</span>
                        ))}
                    </div>
                </div>
                <div className='skills'>
                    <div className='skills-slide skills-slide-reverse'>
                        {SkillsData2?.map((skill, index) => (
                            <span>{skill}</span>
                        ))}
                    </div>
                    <div className='skills-slide skills-slide-reverse'>
                        {SkillsData2?.map((skill, index) => (
                            <span>{skill}</span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills