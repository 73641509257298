
// Clients Logo
import ClientLogo1 from "./ClientsLogo/alkaike.png";
import ClientLogo2 from "./ClientsLogo/recro.png";
import ClientLogo3 from "./ClientsLogo/3.png";
import ClientLogo4 from "./ClientsLogo/4.png";
import ClientLogo5 from "./ClientsLogo/5.png";
import ClientLogo6 from "./ClientsLogo/6.png";
import ClientLogo7 from "./ClientsLogo/7.png";
import ClientLogo8 from "./ClientsLogo/8.png";
import ClientLogo9 from "./ClientsLogo/9.png";
import ClientLogo10 from "./ClientsLogo/10.png";
import ClientLogo11 from "./ClientsLogo/11.png";
import ClientLogo12 from "./ClientsLogo/12.png";
import ClientLogo13 from "./ClientsLogo/13.png";
import ClientLogo14 from "./ClientsLogo/14.png";
import ClientLogo15 from "./ClientsLogo/15.png";
import ClientLogo16 from "./ClientsLogo/foxMatrix.png";
import ClientLogo17 from "./ClientsLogo/innovationM.png";

// Culture Images
import Culture1 from "./Culture/Culture1.jpeg";
import Culture2 from "./Culture/Culture2.jpeg";
import Culture3 from "./Culture/Culture3.png";
import Culture4 from "./Culture/Culture4.png";
import Culture5 from "./Culture/Culture5.png";
import Culture6 from "./Culture/Culture6.jpeg";
import Culture7 from "./Culture/Culture7.jpeg";
import Culture8 from "./Culture/Culture8.jpeg";
import Culture9 from "./Culture/Culture9.jpeg";
import Culture10 from "./Culture/Culture10.jpeg";
import Culture11 from "./Culture/Culture11.jpeg";
import Culture12 from "./Culture/Culture12.jpeg";




// Avatars
import avatar1 from "./Avatars/character-1 1.svg";
import avatar2 from "./Avatars/character-2 1.svg";
import avatar3 from "./Avatars/character-3 1.svg";
import avatar4 from "./Avatars/character-4 1.svg";
import avatar5 from "./Avatars/character-5 1.svg";
import avatar6 from "./Avatars/character-6 1.svg";
import avatar7 from "./Avatars/character-7 1.svg";
import avatar8 from "./Avatars/character-8 1.svg";


// Our Team
import Sumit from "./TeamMembers/Sumit.png";
import Shivali from "./TeamMembers/Shivali.png";
import Gunika from "./TeamMembers/Gunika.png";
import KanchanUttam from "./TeamMembers/Kanchan Uttam.png";
import AnjaliPant from "./TeamMembers/Anjali Pant.jpg";
import RekhaDevtulla from "./TeamMembers/Rekha Devtulla.jpg";

export { default as findJobsImg } from "./findJobsImg.png"
export { default as lookingToHireImg } from "./lookingToHire.png"

// Video
export { default as demoVideo } from "./nufolksVideo.mp4"

// Logo
// export {default as nufolksLogo} from "./nufolksLogo.svg";
export { default as nufolksLogo } from "./nuFolksLogo2.svg";

export { default as Deepa } from "./Deepa.jpg";
export { default as Sachin } from "./Sachin.png";
export { default as group } from "./group.jpg";

// Icons
export { default as quote } from "./quote-left-icon.svg";
export { default as testimonialUser } from "./testimonialUser.svg";
export {default as playButtonIcon } from "./playButton.svg";

export { default as ATS } from "./ATS.png";
export { default as FoxMatrix } from "./FoxMatrix.png";
export { default as cardImg } from "./cardImg.png";
export { default as cardImg2 } from "./cardImg2.png";
export { default as cardImg3 } from "./cardImg3.png";


export { default as outsourcing } from "./outsourcing.png";
export { default as discussion } from "./discussion.png";
export { default as foxMatrixIcon } from "./foxMatrixIcon.png";
export { default as atsIcon } from "./atsIcon.png";
export { default as recruitment } from "./recruitment.png";
export { default as statistics } from "./statistics.png";


// Testimonials
export { default as grnConnect } from "./grnConnect.png";
export { default as unifocus } from "./unifocus.png";
export { default as passionGaming } from "./passionGaming.png";

export { default as visionImg } from "./vision_img.png";
export { default as missionImg } from "./mission_img.png";


// Values Added Icons
export { default as valuesIcon } from "./ValuesAddedLogo/values-100.png";
export { default as technlogyIcon } from "./ValuesAddedLogo/technology-100.png";
export { default as aiIcon } from "./ValuesAddedLogo/ai-100.png";
export { default as interviewIcon } from "./ValuesAddedLogo/interview-100.png";
export { default as linkedInIcon } from "./ValuesAddedLogo/linkedin-100.png";
export { default as diamondIcon } from "./ValuesAddedLogo/diamond-100.png";


export {default as celebration } from "./illustrations/celebration.svg"
export {default as join } from "./illustrations/join.svg"
export {default as hello } from "./illustrations/hello.svg"
export { default as notFoundIcon } from "./server-down.svg"; 




export const teamMembers = {
    Sumit,
    Shivali,
    Gunika,
    KanchanUttam,
    AnjaliPant,
    RekhaDevtulla
}
const Logos = [
    ClientLogo1,
    ClientLogo2,
    ClientLogo3,
    ClientLogo4,
    ClientLogo5,
    ClientLogo6,
    ClientLogo7,
    ClientLogo8,
    ClientLogo9,
    ClientLogo10,
    ClientLogo11,
    ClientLogo12,
    ClientLogo13,
    ClientLogo14,
    ClientLogo15,
    ClientLogo16,
    ClientLogo17
];
export default Logos;

export const CultureImg = {
    Culture1,
    Culture2,
    Culture3,
    Culture4,
    Culture5,
    Culture6,
    Culture7,
    Culture8,
    Culture9,
    Culture10,
    Culture11,
    Culture12,
}

export const candidatesAvatars = {
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
}
export { default as candidates } from "./candidates.png";
