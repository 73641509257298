
import React from 'react';
import style from './FloatingButton.module.css';
import { useNavigate } from 'react-router-dom';

const FloatingButton = ({ label, url }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(url)
    }
    return (
        <button className={label === 'Find your Team' ? style.floating_btn_jobs : style.floating_button} onClick={handleClick}>
            {label}
        </button>
    );
};

export default FloatingButton;