import React from 'react';
import style from './Sidebar.module.css';
import { RxDashboard } from "react-icons/rx";
import { IoPeopleOutline } from "react-icons/io5";
import { FaHands } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import { BiMessageEdit } from "react-icons/bi";

function Sidebar() {
    return (
        <div className={style.sidebar_section}>
            <div className={style.side_nav}>
                <ul>
                    <li>
                        <a href='#dashboard'>
                            <span className={style.side_icon}>
                                <RxDashboard />
                            </span>
                            <span className={style.side_text}>
                                Dashboard
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href='#clients'>
                            <span className={style.side_icon}>
                                <IoPeopleOutline />
                            </span>
                            <span className={style.side_text}>
                                Clients
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href='#values'>
                            <span className={style.side_icon}>
                                <FaHands />
                            </span>
                            <span className={style.side_text}>
                                Pillars of Success
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href='#candidates'>
                            <span className={style.side_icon}>
                                <IoPersonCircleOutline />
                            </span>
                            <span className={style.side_text}>
                                Candidates
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href='#team'>
                            <span className={style.side_icon}>
                                <IoIosPeople />
                            </span>
                            <span className={style.side_text}>
                                Our Team
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href='#testimonials'>
                            <span className={style.side_icon}>
                                <BiMessageEdit />
                            </span>
                            <span className={style.side_text}>
                                Testimonials
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div >
    )
}

export default Sidebar