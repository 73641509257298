import React from 'react'
import './Clients.css'
import Logos from '../../assets'

function Clients() {
    return (
        <div className='clients-section' id='clients'>
            <h1 className={`text-light text-center mainHeading`} data-aos="fade-right" data-aos-duration="1000" data-aos-offset="250">Our Clients</h1>
            <div className='logos-container' data-aos="zoom-in" data-aos-duration="1000">
                <div className='logos'>
                    <div className='logos-slide'>
                        {Logos?.map((image, index) => (
                            <img src={image} alt='clients' />
                        ))}
                    </div>
                    <div className='logos-slide'>
                        {Logos?.map((image, index) => (
                            <img src={image} alt='clients' />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clients