import React from 'react';
import Slider from "react-slick";
import style from "./Team.module.css"
import { teamMembers } from '../../assets';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Team() {
    const teamData = [
        {
            name: 'Sumit Mehta',
            image: teamMembers.Sumit,
            designation: 'BD Executive',
            context: 'Serving as our Business Development Executive, Sumit spearheads efforts to expand our client base and forge new partnerships, leveraging his expertise in sales and market analysis to drive sustainable growth for our company.'
        },
        {
            name: 'Shivali Singh',
            image: teamMembers.Shivali,
            designation: 'Sr. HR Strategy Architect',
            context: "With her role as Senior HR Strategy Architect, Shivali designs and implements strategic HR initiatives that align with our company's objectives, driving organizational effectiveness and employee engagement."
        },
        {
            name: 'Gunika Gangwar',
            image: teamMembers.Gunika,
            designation: 'HR Catalyst',
            context: 'As our HR Catalyst, Gunika is instrumental in driving organizational change and growth by implementing innovative HR strategies and fostering a culture of continuous improvement and development within our team.'
        },
        {
            name: 'Kanchan Uttam',
            image: teamMembers.KanchanUttam,
            designation: 'Sr. Talent Acquisition Specialist',
            context: "As a Senior Talent Acquisition Specialist, Kanchan excels in identifying and recruiting skilled professionals, contributing to our company's talent acquisition strategies and ensuring the right fit for our clients' needs."
        },
        {
            name: 'Rekha Devtulla',
            image: teamMembers.RekhaDevtulla,
            designation: 'Sr. Talent Acquisition Specialist',
            context: "Rekha, our Senior Talent Acquisition Specialist, brings extensive experience in identifying top talent across diverse industries, ensuring our team is equipped with the best professionals contributing significantly to our success."
        },
        {
            name: 'Anjali Pant',
            image: teamMembers.AnjaliPant,
            designation: 'Sr. Talent Acquisition Expert',
            context: " With expertise as a Senior Talent Acquisition Expert, Anjali plays a crucial role in sourcing and securing top-tier talent, leveraging her industry knowledge and network to drive successful placements for our clients."
        },
    ]
    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 15000,
        pauseOnHover: true,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    return (
        <div className={style.team_section} id='team'>
            <h1 className='mainHeading text-center' data-aos="fade-right" data-aos-duration="1000">Our Team Members</h1>
            <div className={style.slider_container} data-aos="fade-up" data-aos-duration="1000">
                <Slider {...settings}>
                    {
                        teamData.map((card, index) => (
                            <div
                                key={index}
                                className={`${style.cardContainer}`}>
                                <div className={style.cardImageContainer}>
                                    <img src={card.image} alt='/' />
                                </div>
                                <div className={style.cardDetailsContainer}>
                                    <div>
                                        <p className={style.teamMemberName}>{card.name}</p>
                                        <p className={style.teamMemberDesignation}>{card.designation}</p>
                                        <p className={style.teamMemberDetails}>{card.context}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </div>
    )
}

export default Team