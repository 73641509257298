import React, { useEffect, useState } from 'react'
import style from './AboutUs.module.css';
import { cardImg, cardImg2, cardImg3, missionImg, visionImg } from '../../assets';
import LeaderShip from '../LeaderShip';

function AboutUs() {
    const [selectedPoint, setSelectedPoint] = useState(null);
    const whyUsData = [
        {
            id: 1,
            heading: "Advanced Technology",
            content: "We leverage state-of-the-art tools like our proprietary Applicant Tracking System (ATS) and AI-driven solutions to enhance efficiency and ensure precise candidate matching.",
        },
        {
            id: 2,
            heading: "Streamlined Processes",
            content: "Our meticulous processes are designed to deliver seamless and effective staffing solutions tailored to your organization's needs, from initial consultation to final placement.",
        },
        {
            id: 3,
            heading: "Core Values",
            content: "Integrity, transparency, and reliability are at the heart of everything we do. We uphold these values in every client interaction, ensuring trustworthy and ethical service."
        },
        {
            id: 4,
            heading: "Proven Expertise",
            content: "With extensive industry knowledge and a track record of successful placements, we bring deep insights and understanding across various sectors.",
        },
        {
            id: 5,
            heading: "Long-Term Partnership",
            content: "We prioritize building enduring relationships with our clients, providing ongoing support and strategic guidance to support your organizational growth and success.",
        }
    ]
    useEffect(() => {
        if (selectedPoint === null) {
            setSelectedPoint(whyUsData[0])
        }
    }, [])
    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedPoint(prevPoint => {
                const currentIndex = prevPoint ? prevPoint.id - 1 : 0; // Convert to zero-based index
                const nextIndex = (currentIndex + 1) % whyUsData.length;
                return whyUsData[nextIndex];
            });
        }, 10000);

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);

    const handlePointClick = (item, index) => {
        setSelectedPoint(item);
    }
    return (
        <div className={style.aboutUs_section}>
            <div className={style.knowAboutContainer}>
                    <h1 data-aos="fade-right" data-aos-duration="1000">Know About Our Company</h1>
                    <p data-aos="fade-up" data-aos-duration="1000">At NuFolks, we redefine staffing with a blend of advanced technology and personalized service. Specializing in connecting businesses with top talent, our innovative Applicant Tracking System (ATS) and FoxMatrix streamline hiring processes. With a commitment to excellence, we pioneer AI-driven tools for efficient candidate sourcing, screening, and assessment. Trusted by clients for superior results, NuFolks is your partner in achieving staffing success.
                    </p>
            </div>
            <div className={style.secondContainer}>
                <div className={style.visionContainer} data-aos="fade-up" data-aos-duration="1000">
                    <div>
                        <h1 className='mainHeading'>Our Vision</h1>
                        <p>At NuFolks, our vision is to lead the staffing industry through innovative technology solutions. We aim to set new benchmarks by integrating advanced technology into every facet of our operations, ensuring unparalleled efficiency and precision in talent acquisition.</p>
                    </div>
                    <img src={visionImg} alt='vision' />
                </div>
                <div className={style.missionContainer} data-aos="fade-up" data-aos-duration="1000">
                    <img src={missionImg} alt='mission' />
                    <div>
                        <h1 className='mainHeading'>Our Mission</h1>
                        <p>NuFolks' mission is to innovate with technology-driven solutions in our ATS and FoxMatrix, meeting industry needs and surpassing client expectations. We pioneer AI tools for efficient candidate sourcing, screening, and assessment, ensuring superior hiring outcomes.</p>
                    </div>
                </div>
            </div>
            <div className={style.leadership_container}>
                <LeaderShip />
            </div>
            <div className={style.thirdContainer}>
                <h1 className='mainHeading' data-aos="fade-right" data-aos-duration="1000">Our Core Values</h1>
                <div className={style.companyValues} data-aos="fade-in" data-aos-duration="1000" data-aos-offset="200">
                    <div className={style.valuesCard}>
                        <img src={cardImg} alt='reputation' />
                        <h2>REPUTATION</h2>
                        <p>Our reputation defines how others perceive our brand. At NuFolks, we prioritize integrity in maintaining our reputation, continually setting new benchmarks, refining methods, and adopting the latest systems to uphold our standards.</p>
                    </div>
                    <div className={style.valuesCard}>
                        <img src={cardImg2} alt='determination' />
                        <h2>DETERMINATION</h2>
                        <p>Determination drives us forward, enabling us to set new benchmarks despite obstacles. Our team remains steadfast in achieving challenging goals through relentless effort, a focused approach, and the optimal systems to deliver the desired outcomes.</p>
                    </div>
                    <div className={style.valuesCard}>
                        <img src={cardImg3} alt='responsibility' />
                        <h2>RESPONSIBILITY</h2>
                        <p>Once we commit to a contract, we take on the complete responsibility for honoring every commitment within it. At NuFolks, integrity is paramount, and we maintain transparency to ensure we uphold our promises with diligence and clarity.</p>
                    </div>
                </div>
            </div>

            <div className={style.whyUsContainer}>
                <h1 className='mainHeading' data-aos="fade-right" data-aos-duration="1000">Why Us?</h1>
                <div className={style.pointsContainer} data-aos="fade-up" data-aos-duration="1000">
                    <div className={style.points}>
                        {
                            whyUsData.map((item, index) => (
                                <div className={`${style.point} ${selectedPoint?.id === item.id ? style.activePoint : ""}`}
                                    onClick={() => handlePointClick(item, index)}>
                                    <h2>{item.heading} </h2>
                                    <h2>{selectedPoint?.id === item?.id ? `-` : `+`}</h2>
                                </div>
                            ))
                        }
                    </div>
                    <div className={style.pointDetail}>
                        <h1>{selectedPoint?.heading ? selectedPoint?.heading : whyUsData[0].heading}</h1>
                        <p>{selectedPoint?.content ? selectedPoint?.content : whyUsData[0].content}</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AboutUs