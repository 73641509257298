import React from 'react';
import style from './Culture.module.css';
import { CultureImg, celebration, hello, join } from '../../assets';
function Culture() {
    return (
        <div className={style.culture_section}>
            <div className={style.culture_main_container}>
                <h1 data-aos="zoom-in">Empowering <span className={style.highlight_text}>People</span>, Driving <span className={style.highlight_text}>Innovation</span></h1>
            </div>
            <div className={style.celebration_container}>
                <div className={style.celeb_box} data-aos="zoom-in">
                    <h1>Be <span className={style.highlight_text}>Great</span></h1>
                    <img src={join} alt='Be Great' />
                </div>
                <div className={style.celeb_box} data-aos="zoom-in">
                    <img src={hello} alt='Do Good' />
                    <h1>Do <span className={style.highlight_text}>Good</span></h1>
                </div>
                <div className={style.celeb_box} data-aos="zoom-in">
                    <h1>Have <span className={style.highlight_text}>Fun</span></h1>
                    <img src={celebration} alt='Do Good' />
                </div>
            </div>
            <div className={style.collage}>
                <h1 className='mainHeading'>Celebrating Diversity</h1>
                <div className={style.gridContainer}>
                    <div className={style.gridRow_first}>
                        <div className={style.gridItem}><img src={CultureImg.Culture2} alt='celebration' /></div>
                        <div className={style.gridItem}><img src={CultureImg.Culture8} alt='celebration' /></div>
                        <div className={`${style.gridItem} ${style.fullWidth}`}><img src={CultureImg.Culture3} alt='celebration' /></div>
                    </div>
                    <div className={style.gridRow_second}>
                        <div className={style.gridItem}><img src={CultureImg.Culture5} alt='celebration' /></div>
                        <div className={style.gridItem}><img src={CultureImg.Culture6} alt='celebration' /></div>
                    </div>
                    <div className={style.gridRow_third}>
                        <div className={style.gridItem}><img src={CultureImg.Culture1} alt='celebration' /></div>
                        <div className={style.gridItem}><img src={CultureImg.Culture7} alt='celebration' /></div>
                        <div className={`${style.gridItem} ${style.fullWidth}`}><img src={CultureImg.Culture4} alt='celebration' /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Culture