import React from 'react'
import style from './Candidates.module.css';
import { candidatesAvatars } from '../../assets';
import CandidateCard from '../../components/CandidateCard';
function Candidates() {
    const cardData = [
        {
            name: "Mansi Uppal",
            exp: "6 years",
            designation: "HR Operations",
            avatar: candidatesAvatars.avatar1,
            background: '#FAEA7F',
        },
        {
            name: "Naman Sehgal",
            exp: "2 years",
            designation: "Digital Marketing",
            avatar: candidatesAvatars.avatar2,
            background: '#F8D084',
        },
        {
            name: "Anand (Andy) Arora",
            exp: "7 years",
            designation: "Experience Designer",
            avatar: candidatesAvatars.avatar3,
            background: '#93A7DB',
        },
        {
            name: "Gaurav Sikka",
            exp: "12 years",
            designation: "Engineering Manager",
            avatar: candidatesAvatars.avatar4,
            background: '#AAD9DA',
        },
        {
            name: "Chirag Badwal",
            exp: "7.5 years",
            designation: "Sr. Python Developer",
            avatar: candidatesAvatars.avatar5,
            background: '#ABD7E3',
        },
        {
            name: "Mr. Amrit Bhatti",
            exp: "32 years",
            designation: "Account Director",
            avatar: candidatesAvatars.avatar6,
            background: '#DD7B95',
        },
        {
            name: "Pratham Saini",
            exp: "4 years",
            designation: "Flutter Developer",
            avatar: candidatesAvatars.avatar7,
            background: '#F8D084',
        },
        {
            name: "Mitesh Reddy",
            exp: "5 years",
            designation: "L2 Application Support",
            avatar: candidatesAvatars.avatar8,
            background: '#FAEA7F',
        }
    ]
    return (
        <div className={style.candidates_section} id='candidates'>
            <h1 className='mainHeading' data-aos="fade-right" data-aos-duration="1000">Camaraderie with our candidates</h1>
            <div className={style.candidates_card_container}>
                {cardData.map((data,index) => (
                    <div
                        key={index}
                        data-aos="flip-left"
                        data-aos-duration="1000"
                        // data-aos-offset={index < 4 ? "300" : "50"}
                        data-aos-offset="50"
                        data-aos-delay={index * 200}
                    >
                        <CandidateCard data={data} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Candidates