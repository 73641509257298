import React from 'react'
import style from './Terms.module.css';
function Terms() {
    return (
        <div className={style.termsWrapper}>
            <div className={style.termsContainer}>
                <h1>Terms and Conditions</h1>
                <p>This Privacy Policy demonstrates our strong commitment to helping our users and visitors better understand the information we collect about them and what may happen to that information. This Privacy Policy was last updated on Dec 02, 2023, and is effective as of that date.
                    NuFolks values your privacy. In this Privacy Policy ("Policy"), we outline the information we collect about you when you visit our website www.nufolks.com ("Website") and provide your information while using the services available on the Website and other services provided by NuFolks ("Services"), as well as how we use and disclose that information.
                    If you have any questions or comments about the Privacy Policy, please contact us at it@nufolks.com . Your use of the Website and/or Services and any personal information you provide on the Website remains subject to the terms of this Policy and the Stanza Living Terms of Use.</p>
                <h1>A. Collection of Your Personal Information</h1>
                <h2>1. Collection of Personal Information</h2>
                <p>"Personal information" refers to information that can be used to identify or contact you, either alone or in combination with other information, such as your name, address, email address, and phone number.
                    The type of personal information we collect from you varies based on your specific interactions with our Website. Personal information collected includes, but is not limited to, your name, gender, age, postal address, phone number, email address, contact preferences, ID proof, bank account/credit card/debit card details, proof of employment, proof of educational background, and other information that helps us verify your identity.
                    If deemed necessary, we may also ask for and collect supplemental information from third parties, such as credit information from a credit bureau (to the extent permitted by law) or information to verify any identification details you provide during registration.
                    You undertake that your personal information shall always be accurate, complete, and up-to-date. We will make reasonable efforts to provide you with the opportunity to request correction of your personal information if it is inaccurate or to delete it, subject to applicable law. We may decline to process requests that are contrary to the terms laid down under this Policy, Website terms and conditions, or any applicable laws, require disproportionate technical effort, jeopardize the privacy of others, or are extremely impractical.
                </p>
                <h2>2. Collection of Personal Information from Social Networking Sites</h2>
                <p>You may be permitted to log into our Website through your social networking accounts. If you do so, you may enter the email address and password you use for such accounts. We will ask for permission to access and collect your basic information (including your name, profile picture, gender, networks, user IDs, date of birth, email address, and any other information set to public on your account). If you grant us access, we will have access to this information even if you have chosen not to make it public. We store this information with other information we collect from or about you.</p>
                <p>Social networking sites control the information they collect from you. For information about how these sites use and disclose your information, including any information you make public, please consult the respective social networking sites’ privacy policies. We have no control over how any third-party site uses or discloses the personal information it collects about you.</p>
                <h2>3. Collection of Automatic Information, Use of Cookies, and Other Tracking Devices</h2>
                <p>We and our third-party service providers, which include ad networks, use cookies, web beacons, and other tracking technologies to collect information about your use of our Website and Services, such as your browser type, ISP, operating system, domain name, access time, the URL of the previous website you visited, page views, IP address, and the type of device you use. We also track how frequently you visit our Website and use our Services. We use this information (including information collected by our third-party service providers) for Website analytics (to determine which portions of our Website are used most frequently and what users like/dislike), to assist in determining relevant advertising (both on and off our Website), to evaluate the success of our advertising campaigns, and as otherwise described in this policy. We may obtain your device ID, which is sent to NuFolks' servers and used in fraud prevention efforts.</p>
                <p>Cookies. We and our third-party service providers collect information from you by using cookies. A cookie is a small file stored on a user’s computer hard drive containing information about the user. The cookie helps us analyze web traffic or informs us about your use of a particular website. Cookies allow web applications to respond to you as an individual, tailoring its operations to your needs, likes, and dislikes by gathering and remembering information about your preferences. When you visit the Website, we may send one or more cookies (i.e., a small text file containing a string of alphanumeric characters) to your computer that identifies your browser.</p>
                <p>Some of these cookies may be connected to third-party companies or websites. The terms of use of such cookies are governed by this Policy and the privacy policy of the relevant third-party company or website. For example, Google measures the performance of advertisements by placing cookies on your computer when you click on ads. If you visit the Website when you have such cookies on your computer, we and Google will be able to tell that you saw the ad delivered by Google. The terms of use of these cookies are governed by this Policy and Google’s Privacy Policy.</p>
                <p>Disabling Cookies. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. If you disable cookies, you may be prevented from taking full advantage of the Website because it may not function properly if the ability to accept cookies is disabled.</p>
                <p>Clear GIFs, pixel tags, and other technologies. Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which are stored on your computer’s hard drive, clear GIFs are embedded invisibly on web pages. We may use clear GIFs (a.k.a. web beacons, web bugs, or pixel tags) in connection with our Website to, among other things, track the activities of Website visitors, help us manage content, and compile statistics about Website usage. We and our third-party service providers also use clear GIFs in HTML emails to our customers to help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded.</p>
                
                <h1>B. How NuFolks Uses the Information We Collect</h1>
                <h2>1. HOW PERSONAL INFORMATION IS USED</h2>
                <p>We collect your personal information and aggregate information about the use of our Website and Services to better understand your needs and provide you with an enhanced Website experience. Specifically, we may use your personal information for any of the following purposes, to which you consent:</p>
                <ul>
                    <li>To provide our Services to you, including registering you for our Services, verifying your identity and authorization to use our Services, and enabling your use of our Website and Services.</li>
                    <li>For customer support and to respond to your inquiries.</li>
                    <li>For internal record-keeping purposes.</li>
                    <li>To process billing and payments, including sharing with third-party payment gateways in connection with the Website and/or Services.</li>
                    <li>To enhance and maintain our Website and Services (for example, tracking information entered through the “Search” function to improve user experience and troubleshoot where applicable).</li>
                    <li>To periodically send promotional emails to the email address you provide regarding new products from NuFolks, special offers, or other information we believe you may find interesting.</li>
                    <li>To contact you via email, telephone, facsimile, mail, or, where requested, by text message, to deliver specific services or information.</li>
                    <li>For market research purposes, including customizing the Website or Services based on your interests.</li>
                    <li>Using demographic information (e.g., age, postal code, addresses) to effectively promote goods and services to appropriate audiences and for research and analytical purposes.</li>
                    <li>To resolve disputes, protect ourselves and other users of our Website and/or Services, and enforce our Website’s terms and conditions.</li>
                    <li>Comparing personal information collected through the Website and/or Services with information obtained from third parties to verify accuracy.</li>
                    <li>Combining aggregate data with personal information collected about you.</li>
                </ul>
                <p>Your registration or provision of information on the Website constitutes consent to be contacted for the purposes mentioned above, including</p>
                <p> (i) on the mobile number provided by you, even if registered with the National Customer Preference Register (NCPR) and opted out of receiving promotional calls/messages, and</p>
                <p>(ii) via SMS or email notifications or other electronic means.</p>

                <h1>C. Electronic Newsletters, Invitations, Polls, and Surveys</h1>
                <p>At our discretion, NuFolks may offer free services on the Website, which you may choose to use or receive. Some services may require additional personal information, as detailed below:</p>
                <h2>1. ELECTRONIC NEWSLETTERS</h2>
                <p>We may offer a free electronic newsletter to users. We collect email addresses from users who sign up for our newsletter mailing list. Users can opt out of receiving newsletters during registration, through a link provided in each newsletter for subscription management, or by adjusting preferences in their Profile Settings.</p>
                <h2>2. “SEND TO A FRIEND”</h2>
                <p>Users can voluntarily forward a link, page, or document to others via “send to a friend.” To do so, users provide their email address and the recipient's email address. The user’s email address is used only for transmission errors and to identify the sender to the recipient, with no other purposes.</p>
                <h2>3. POLLING</h2>
                <p>Interactive polls may be offered to users to share opinions with our audience about important issues, Services, or the Website. Poll responses are aggregated and not identifiable to individual users. A tagging system may be used to ensure users vote only once on a particular question, unlinked to personal information.</p>
                <h2>4. SURVEYS</h2>
                <p>We may conduct user surveys periodically to tailor our content to Website users. Individual survey responses are not shared with third parties, but aggregate data may be shared with third-party service providers, partners, and others. Aggregate survey data may also be published on our Website for viewing and download by other users.</p>
            </div>
        </div>
    )
}

export default Terms